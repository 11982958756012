(window as any).process = {
    env: {
        DEBUG: undefined,
    },
};

console.log('Version:', environment.version);
import { LOCALE_ID, StaticProvider, enableProdMode, provideZoneChangeDetection } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { getSelectedLanguage } from '@fuse/utils/translate';
import { TranslateService } from '@ngx-translate/core';
import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';

const staticProviders: StaticProvider = [
    provideAnimationsAsync(),
    { provide: LOCALE_ID, useFactory: getSelectedLanguage, deps: [TranslateService] },
    provideZoneChangeDetection({ eventCoalescing: true, runCoalescing: true }),
];

if (environment.production) {
    enableProdMode();
}
platformBrowserDynamic()
    .bootstrapModule(AppModule, {
        providers: [staticProviders],
    })
    .catch(err => console.error(err));
